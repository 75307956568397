import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export default function MenuStackLayout() {
  return (
    <Stack
      initialRouteName="index"
      screenOptions={{ header: getScreenTitleHeader(), title: 'Cart' }}
    >
      <Stack.Screen name="index" />
      <Stack.Screen name="(checkout)" />
    </Stack>
  );
}

// Disabled because this is causing module import errors on production build
// Presumably because it duplicate `fresh/routing/src/routes/menu/_layout.tsx`
// export const unstable_settings = {
// };
