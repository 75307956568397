import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { Button, IconAdd, IconRemove, IconStarRateFill, Text, tokens } from '@fhs/ui';

import { UsePointsOnItem, useMutation } from '../../screens/client';
import { ItemEntry } from '../../types';

type Props = {
  item: ItemEntry;
  canUsePoints: boolean;
  points: number;
  showQuantitySelectors: boolean;
  // This will be when an appliedIncentive is correalted
  // to this lineId
  appliedSavings: number | null;
};

export function CartItem({
  appliedSavings = 0,
  canUsePoints,
  item,
  points,
  showQuantitySelectors = true,
}: Props) {
  const [{ fetching: mutatingToUsePoints }, utilizePointsForItem] = useMutation(UsePointsOnItem, {
    lineId: item.lineId,
  });
  const itemPrice = item.price - appliedSavings;

  return (
    <View>
      <View style={styles.top}>
        <View style={styles.productInfoContainer}>
          <Text.Ui size="md" weight="bold" numberOfLines={1}>
            {item.displayName}
          </Text.Ui>

          {canUsePoints && (
            <Button
              size="xs"
              type="outline"
              style={styles.pointsButton}
              loading={mutatingToUsePoints}
              disabled={mutatingToUsePoints}
              onPress={() => utilizePointsForItem()}
            >
              {!mutatingToUsePoints && (
                <Button.Icon color={tokens.colors.$houseYellow}>
                  <IconStarRateFill />
                </Button.Icon>
              )}
              <Button.Text style={styles.pointsButtonText}>
                Use {points.toLocaleString()}
              </Button.Text>
            </Button>
          )}
        </View>
        {/* TODO: List modifiers */}
        <View style={styles.totalsContainer}>
          <View style={styles.prices}>
            <Text.Ui size="md" weight="bold" style={itemPrice === 0 && styles.crossedOutPrices}>
              ${item.price / 100}
            </Text.Ui>
            {itemPrice === 0 && (
              <Text.Ui size="lg" weight="bold" style={styles.freePrice}>
                {itemPrice === 0 ? 'Free' : itemPrice / 100}
              </Text.Ui>
            )}
          </View>
        </View>
      </View>
      <View style={styles.bottom}>
        <View style={styles.editRemoveContainer}>
          <Link href={`/v2/menu/pepperoni-pizza-meatball-sub?lineId=${item.lineId}`} asChild>
            <Button type="ghost" style={styles.editremoveButton}>
              <Button.Text style={styles.underlineButtons}>Edit</Button.Text>
            </Button>
          </Link>

          <Link href={`/v2/cart?action=remove-item&id=${item.lineId}`} asChild>
            <Button type="ghost" style={styles.editremoveButton}>
              <Button.Text style={styles.underlineButtons}>Remove</Button.Text>
            </Button>
          </Link>
        </View>
        {showQuantitySelectors && (
          <View style={styles.quantityContainer}>
            <Button style={styles.quantityButton} type="outline" size="xs">
              <Button.Icon>
                <IconRemove />
              </Button.Icon>
            </Button>
            <Text style={styles.quantityInput}>1</Text>
            <Button type="outline" size="xs" style={styles.quantityButton}>
              <Button.Icon>
                <IconAdd />
              </Button.Icon>
            </Button>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  freePrice: {
    color: tokens.colors.$success,
  },
  crossedOutPrices: {
    color: tokens.colors.$disabledText,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  pointsButton: {
    borderColor: tokens.colors.$black10,
    padding: 8,
  },
  pointsButtonText: {
    color: tokens.colors.$black,
  },
  productInfoContainer: {
    minHeight: 31,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalsContainer: {
    marginTop: 12,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  underlineButtons: {
    textDecorationLine: 'underline',
    paddingLeft: 0,
    paddingRight: 0,
  },
  quantityInput: {
    fontFamily: 'Montserrat',
    alignContent: 'center',
    textAlign: 'center',
    height: 28,
    width: 28,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: tokens.colors.$black10,
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 100,
    gap: 8,
  },
  top: {
    padding: 16,
  },
  bottom: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    height: 56,
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
  },
  quantityButton: {
    // reset minHeight from the base component
    minHeight: 'auto',
    width: 28,
    height: 28,
    padding: 0,
  },
  editRemoveContainer: {
    flexDirection: 'row',
    gap: 16,
  },
  prices: {
    justifyContent: 'flex-end',
  },
  editremoveButton: {
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
});
