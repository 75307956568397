import { Link } from 'expo-router';
import { View } from 'react-native';

import { GetCart, useQuery } from '@fhs/cart';
import { OffersAndRewardsPage } from '@fhs/loyalty';
import { Button } from '@fhs/ui';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function Screen() {
  const { data: cart } = useQuery(GetCart);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <OffersAndRewardsPage />
      </View>
      <View style={{ padding: 16, backgroundColor: '#fff' }}>
        <Link href="/v2/cart" asChild>
          <Button>
            <Button.Text>{`Checkout ${
              cart?.entries.length ? `(${cart.entries.length}` : ''
            })`}</Button.Text>
          </Button>
        </Link>
      </View>
    </View>
  );
}
