import { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { Text, tokens } from '@fhs/ui';

export function Badge({ children }: PropsWithChildren) {
  return (
    <View style={styles.badge}>
      <Text.Ui weight="semibold" size="sm" style={styles.badgeText}>
        {children}
      </Text.Ui>
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    backgroundColor: tokens.colors.$success,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 3.5,
  },

  badgeText: {
    color: tokens.colors.$white,
  },
});
