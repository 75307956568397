import { StyleSheet, View, type ViewProps } from 'react-native';

import { Text, YStack } from '@fhs/ui';

type TProductDetailSectionHeader = ViewProps & {
  title: string;
  subtitle?: string;
  ariaLevel?: number;
};

type TTextContainerProps = {
  title: string;
  subtitle?: string;
};

/**
 * A component that renders a section header with a title and a subtitle.
 *
 * @param {TProductDetailSectionHeader} props - The props for the component.
 * @param {string} props.title - The title text to display.
 * @param {string} props.subtitle - The optional subtitle text to display.
 * @param {ViewProps} props.viewProps - Additional view props to be passed to the container View.
 * @returns {JSX.Element} The rendered section header.
 */
export function ProductDetailSectionHeader({
  title,
  subtitle,
  ariaLevel = 2,
  ...viewProps
}: TProductDetailSectionHeader): JSX.Element {
  return (
    <View role="heading" aria-level={ariaLevel} {...viewProps}>
      <TextContainer title={title} subtitle={subtitle} />
    </View>
  );
}

function TextContainer({ title, subtitle }: TTextContainerProps) {
  return (
    <YStack style={styles.textContainer}>
      <Text.Ui size="md" weight="semibold">
        {title}
      </Text.Ui>
      {subtitle && <Text.Ui size="sm">{subtitle}</Text.Ui>}
    </YStack>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    alignItems: 'flex-start',
    gap: 8,
  },
});
