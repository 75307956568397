import { MenuListData } from '../types';

export const getMenuListData = (_storeId?: string): MenuListData => {
  // const data = require(`../backend/data/generated/${storeId}/list.json`);
  const data = require('../backend/data/generated/0001/list.json');
  return {
    headers: data.headers,
    sections: data.sections,
  };
};
