import { Image } from 'expo-image';
import { StyleSheet, type ViewProps } from 'react-native';

import { ActionSheet, Button, Text, YStack } from '@fhs/ui';

type TComboUpSell = ViewProps & {
  isVisible: boolean;
  headerTitle?: string;
  acceptButtonTitle: string;
  closeButtonTitle: string;
  description?: string;
  price?: string;
  handleAccept: () => void;
  handleClose: () => void;
};

/**
 * A component that renders an action sheet for an upsell offer, including an image,
 * optional title, description, price, and two action buttons.
 *
 * @param {TComboUpSell} props - The props for the component.
 * @param {boolean} props.isVisible - Controls the visibility of the action sheet.
 * @param {string} [props.headerTitle] - The optional title text to display.
 * @param {string} [props.acceptButtonTitle] - The text to display for accept button.
 * @param {string} [props.closeButtonTitle] - The text to display for close button.
 * @param {string} [props.description] - The optional description text to display.
 * @param {string} [props.price] - The optional price text to highlight within the description.
 * @param {() => void} props.handleAccept - Callback function to handle the accept action of the sheet.
 * @param {() => void} props.handleClose - Callback function to handle the close action of the sheet.
 * @returns {JSX.Element} The rendered ComboUpSell component.
 */
export function ComboUpSell({
  isVisible,
  headerTitle,
  acceptButtonTitle,
  closeButtonTitle,
  description,
  price,
  handleAccept,
  handleClose,
}: TComboUpSell): JSX.Element {
  return (
    <ActionSheet isVisible={isVisible} onClose={handleClose}>
      <YStack style={styles.container}>
        <Image
          source={require('./combo.webp')}
          alt="combo"
          contentFit="contain"
          style={{
            height: 180,
            width: '100%',
          }}
          placeholder={{ thumbhash: 'cCkOJIL4lrhqmXV+hZiPdIJHCQ==' }}
        />
        {headerTitle && (
          <Text.Ui size="lg" weight="semibold">
            {headerTitle}
          </Text.Ui>
        )}
        {(description || price) && (
          <Text.Ui size="md" style={styles.description}>
            {description} <strong>{price}</strong>
          </Text.Ui>
        )}

        <Button
          size="lg"
          aria-label={acceptButtonTitle}
          onPress={handleAccept}
          style={styles.button}
        >
          <Button.Text>{acceptButtonTitle}</Button.Text>
        </Button>
        <Button
          size="lg"
          type="outline"
          aria-label={closeButtonTitle}
          onPress={handleClose}
          style={styles.button}
        >
          <Button.Text>{closeButtonTitle}</Button.Text>
        </Button>
      </YStack>
    </ActionSheet>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    gap: 8,
  },
  button: {
    minWidth: '80%',
  },
  description: {
    maxWidth: '60%',
    textAlign: 'center',
    marginBottom: '3%',
  },
});
