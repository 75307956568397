export type ID = string;

export type ServiceMode = 'pickup' | 'delivery';

export type CartState =
  | 'VALID'
  | 'NEEDS_LOYALTY_VALIDATION'
  | 'LOYALTY_INVALID'
  | 'PRICE_REQUESTED'
  | 'PRICE_SUCCESSFUL'
  | 'PRICE_ERROR'
  | 'INSERT_REQUESTED'
  | 'INSERT_ERROR'
  | 'INSERT_SUCCESSFUL';

export type ItemEntry = {
  type: 'item';
  itemId: ID;
  lineId: ID;
  displayName: string;
  imageUrl: string;
  price: number;
  modifiers: Array<{}>;

  // This will be supplied by a loyalty
  // api during the loyalty validation phase
  pointCost: number | null;
};

// What is this type def??
type RuleError = unknown;
type BenefitError = unknown;
type IncentiveType = unknown;
export type Discount = {
  appliedCartEntries: string[];
  ruleErrors?: RuleError[];
  benefitErrors?: BenefitError[];
  savingsAmount: null | number;
  type: IncentiveType;
};

export type Store = { name: string; id: ID };

export type Incentive = Discount & {
  offerId: string;
  appliedCartEntries: string[];
  title: string;
  description: string;
};

type Metadata = {
  rbiOrderId?: string;
} & { [key: string]: any };

export type CartModel = {
  appliedIncentives: Incentive[];

  donationAmount: number;
  userId: string;
  entries: ItemEntry[];
  store: Store | null;
  state: CartState;
  version: number;
  serviceMode: ServiceMode;
  metadata: Metadata;
};
