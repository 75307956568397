import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export default function OrderLayout() {
  return <Stack screenOptions={{ header: getScreenTitleHeader(), title: 'Order Status' }} />;
}

// Disabled because this is causing module import errors on production build
// Presumably because it duplicate `fresh/routing/src/routes/menu/_layout.tsx`
// export const unstable_settings = {
// };
