import { StyleSheet } from 'react-native';

import { RadioGroup } from '@fhs/ui/src/components/radio-group';

import { IconSandwich } from './icon-sandwich';
import { SizeCard } from './size-card';

type SizeSelectorProps<SizeType extends string> = {
  value: SizeType;
  onChange: (nextSize: SizeType) => void;
};

export function SizeSelector<SizeType extends string>(props: SizeSelectorProps<SizeType>) {
  return (
    <RadioGroup style={styles.radioGroup} value={props.value} onChange={props.onChange}>
      <RadioGroup.Item value="small-sandwich">
        <SizeCard
          title="Small"
          description="$7.99"
          renderIcon={({ disabled, selected }) => (
            <IconSandwich sandwichType="small" disabled={disabled} selected={selected} />
          )}
        />
      </RadioGroup.Item>
      <RadioGroup.Item value="medium-sandwich">
        <SizeCard
          title="Medium"
          description="$7.99"
          renderIcon={({ disabled, selected }) => (
            <IconSandwich sandwichType="medium" disabled={disabled} selected={selected} />
          )}
        />
      </RadioGroup.Item>
      <RadioGroup.Item value="large-sandwich">
        <SizeCard
          title="Large"
          description="$7.99"
          renderIcon={({ disabled, selected }) => (
            <IconSandwich sandwichType="large" disabled={disabled} selected={selected} />
          )}
        />
      </RadioGroup.Item>
    </RadioGroup>
  );
}

const styles = StyleSheet.create({
  radioGroup: {
    flexDirection: 'row',
    padding: 16,
    gap: 16,
  },
});
