import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import {
  Button,
  IconAccount,
  IconCalendar,
  IconDrive,
  IconStore,
  IconTelephone,
  Text,
  tokens,
} from '@fhs/ui';

export function OrderPickupInformationSection() {
  return (
    <View style={styles.container}>
      <View style={[styles.section, styles.locationSection, styles.padding16]}>
        <View style={[styles.row, styles.marginBottom4]}>
          <View style={styles.iconCircle}>
            <IconStore size={24} />
          </View>
          <Text.Heading type="three">Firehouse Subs Mandarin</Text.Heading>
        </View>

        <View style={styles.addressPhoneContainer}>
          <View style={[styles.row]}>
            <Text.Ui size="md">
              <Text.Ui size="md" weight="bold">
                Address:
              </Text.Ui>{' '}
              9825 San Jose Blvd, Jacksonville
            </Text.Ui>
          </View>

          <View style={styles.row}>
            <Text.Ui size="md">
              <Text.Ui size="md" weight="bold">
                Phone:
              </Text.Ui>{' '}
              <Link style={styles.phone} href="tel:9048862179">
                (904) 886-2179
              </Link>
            </Text.Ui>
          </View>
        </View>

        <View style={[styles.row, styles.directionButtonContainer]}>
          <Button style={styles.restaurantButtons}>
            <Button.Icon>
              <IconDrive size={16} />
            </Button.Icon>
            <Button.Text style={styles.restaurantButtonsText}>Get Directions</Button.Text>
          </Button>

          <Button type="outline" style={styles.restaurantButtons}>
            <Button.Icon>
              <IconTelephone size={16} />
            </Button.Icon>
            <Button.Text style={styles.restaurantButtonsText}>Call Restaurant</Button.Text>
          </Button>
        </View>
      </View>

      <View style={[styles.section, styles.mapImage]} />

      <View style={[styles.section, styles.padding16, styles.row]}>
        <View style={styles.iconCircle}>
          <IconCalendar size={24} />
        </View>

        <View>
          <Text.Ui style={styles.marginBottom4} size="md" weight="bold">
            12:15 AM
          </Text.Ui>
          <Text.Ui size="md">Today • Aug 1, 2023</Text.Ui>
        </View>
      </View>

      <View style={[styles.section, styles.padding16, styles.row]}>
        <View style={styles.iconCircle}>
          <IconAccount size={24} />
        </View>

        <View>
          <Text.Ui style={styles.marginBottom4} size="md" weight="bold">
            Dave Smith
          </Text.Ui>
          <Text.Ui size="md">(904) 881-7259</Text.Ui>
          <Text.Ui size="md">dave.smith@gmail.com</Text.Ui>
        </View>
      </View>

      <View style={[styles.section, styles.padding16, styles.row]}>
        <View style={styles.iconCircle}>
          <IconDrive size={24} />
        </View>

        <View>
          <Text.Ui style={styles.marginBottom4} size="md" weight="bold">
            Rapid Rescue Lane
          </Text.Ui>
          <Text.Ui size="md">Drive to the pick up window</Text.Ui>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  section: {
    width: '100%',
    borderBottomColor: tokens.colors.$black10,
    borderBottomWidth: 1,
  },
  mapImage: {
    height: 100,
    backgroundColor: tokens.colors.$black10,
  },
  directionButtonContainer: {
    gap: 8,
  },
  restaurantButtons: {
    flex: 1,
  },
  restaurantButtonsText: {
    fontSize: 12,
  },
  addressPhoneContainer: {
    gap: 4,
  },
  phone: {
    textDecorationLine: 'underline',
    color: tokens.colors.$houseRed,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
    marginRight: 12,
  },
  padding16: {
    padding: 16,
  },
  marginBottom4: {
    marginBottom: 4,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationSection: {
    height: 182,
    gap: 12,
  },
  container: {
    alignItems: 'center',
    minHeight: 200,
    width: 343,
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: 1,
    borderRadius: 8,
  },
});
