import { useRef } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { Text, XStack } from '@fhs/ui';

import type { MenuSectionItem as MenuSectionItemType } from '../types';

import { MenuSectionItem } from './menu-section-item';

type MenuSectionProps = {
  isSelected: boolean;
  onLayout: (event: LayoutChangeEvent) => void;
  section: {
    displayName: string;
    slug: string;
    id: string;
    url: string;
    items: MenuSectionItemType[];
  };
};

export function MenuSection(props: MenuSectionProps) {
  const containerRef = useRef(null);
  const { isSelected, section } = props;

  if (!isSelected) {
    // TODO(simplified-menu): If not selected, but in view, notify the parent
  }

  return (
    <View ref={containerRef} onLayout={props.onLayout} style={styles.container}>
      <Text.Heading type="three" id={section.slug}>
        {section.displayName}
      </Text.Heading>
      <XStack style={styles.containerItems}>
        {section.items.map(item => (
          <MenuSectionItem key={item.slug} item={item} />
        ))}
      </XStack>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  containerItems: {
    flexWrap: 'wrap',
    alignItems: 'stretch',
    gap: 16,
  },
});
