import { Image } from 'expo-image';
import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { Text, tokens } from '@fhs/ui';

import type { MenuSectionItem as MenuSectionItemType } from '../types';
export type { ScreenMenuListProps } from '../types';

type MenuSectionItemProps = {
  item: MenuSectionItemType;
};

type MenuSectionItemStatusProps = {
  text: string;
};

const statusMapping = {
  new: 'New!',
  'limited-time': 'Limited Time!',
};

export function MenuSectionItemStatus(props: MenuSectionItemStatusProps) {
  const value = statusMapping[props.text];
  return (
    // return the container, but not the text
    // so that the the container can fill the space
    <View style={[styles.statusContainer, value && styles.statusContainerActive]}>
      {value && <Text style={styles.statusText}>{value}</Text>}
    </View>
  );
}

export function MenuSectionItem(props: MenuSectionItemProps) {
  const { item } = props;
  const isAvailable = item.isAvailable;
  const url = item.__legacyUrl;
  const Content = (
    <View style={styles.container}>
      <View style={styles.containerTop}>
        <MenuSectionItemStatus text={item.status} />
      </View>
      <View style={styles.containerBottom}>
        <Text style={styles.textDisplayName}>{item.displayName}</Text>
        <Text style={styles.textDescription} numberOfLines={2}>
          {item.descriptionShort}
        </Text>
      </View>
      <Image source={{ uri: item.imageUrl }} contentFit="contain" style={styles.image} />
    </View>
  );
  if (!isAvailable) {
    return <View style={[styles.container, styles.disabled]}>{Content}</View>;
  }
  return <Link href={url}>{Content}</Link>;
}

const styles = StyleSheet.create({
  // TODO: change media query based on media query
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 180,
    minWidth: 150,
  },
  disabled: {
    opacity: 0.3,
  },
  containerTop: {
    minHeight: 32,
  },
  containerBottom: {
    marginTop: 32,
    paddingTop: 42,
    paddingHorizontal: 16,
    paddingBottom: 16,
    borderColor: tokens.colors.$black10,
    borderWidth: 1,
    borderRadius: 8,
  },
  image: {
    height: 92,
    position: 'absolute',
    paddingVertical: 32,
    top: 16,
    left: 0,
    right: 0,
    overflow: 'hidden',
  },
  textDisplayName: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '600',
    lineHeight: 20,
    minHeight: 48,
    color: tokens.colors.$houseDark,
  },
  textDescription: {
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 16,
    color: tokens.colors.$houseDark,
  },
  statusContainer: {
    display: 'flex',
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRadius: 6,
    position: 'absolute',
    top: 0,
    right: 0,
    overflow: 'hidden',
  },
  statusContainerActive: {
    backgroundColor: tokens.colors.$success,
  },
  statusText: {
    fontSize: 12,
    fontWeight: '800',
    textTransform: 'uppercase',
    color: tokens.colors.$white,
  },
});
