import { Image, ImageBackground } from 'expo-image';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, ScrollView, Text, YStack, tokens } from '@fhs/ui';

import type { MenuItem } from '../types';

import { ComboUpSell } from './combo-up-sell';
import { ProductDetailSectionHeader } from './product-detail-section-header';
import { SizeSelector } from './size-selector';

type MenuSectionProps = {
  item: MenuItem;
};
type TSizeKey = 'small-sandwich' | 'medium-sandwich' | 'large-sandwich';

export function MenuItemDetails(props: MenuSectionProps) {
  const { item } = props;
  const [sizeValue, setSizeValue] = useState<TSizeKey>('medium-sandwich');
  const [comboUpSell, setComboUpSell] = useState<boolean>(false);

  const handleSizeChange = (value: TSizeKey) => {
    setSizeValue(value);
  };

  return (
    <ScrollView style={styles.container}>
      <YStack style={styles.containerPadding}>
        <YStack style={styles.containerTitle}>
          <Text.Heading type="one" style={styles.textTitle}>
            {item.displayName}
          </Text.Heading>
          <Text.Heading type="four" style={styles.textSubTitle}>
            Medium Size • 720 Cal
          </Text.Heading>
        </YStack>
        <ImageBackground
          contentFit="contain"
          source={require('../assets/img/menu_item_detail_bg_2477_1058.webp')}
          style={styles.containerImageBackground}
        >
          <Image source={{ uri: item.imageUrl }} contentFit="contain" style={styles.image} />
        </ImageBackground>
        <Text.Paragraph size="md" style={styles.textDescription}>
          {item.description}
        </Text.Paragraph>
      </YStack>
      <YStack style={[styles.containerSection, styles.containerPadding]}>
        <Text.Heading type="four">Size</Text.Heading>
      </YStack>
      <SizeSelector value={sizeValue} onChange={handleSizeChange} />
      <YStack style={[styles.containerSection, styles.containerPadding]}>
        <Text.Heading type="four">Choices</Text.Heading>
      </YStack>
      <View style={{ padding: 16 }}>
        <Button
          style={styles.comboUpSellButton}
          onPress={() => {
            setComboUpSell(prev => !prev);
          }}
          aria-label="Dispatch ComboUpSell"
          size="sm"
        >
          <Button.Text>Dispatch ComboUpSell</Button.Text>
        </Button>
      </View>
      <ComboUpSell
        headerTitle="Make it a Combo"
        acceptButtonTitle="Make it a Combo"
        closeButtonTitle="No, thanks"
        description="Add any size drink + chips or cookie for only"
        price="$3.49"
        isVisible={comboUpSell}
        handleAccept={() => setComboUpSell(false)}
        handleClose={() => setComboUpSell(false)}
      />
      <ProductDetailSectionHeader
        title="Make it a Combo"
        subtitle="Add any size drink + chips or cookie"
        style={styles.productDetailSectionHeader}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    // TOOD(simplified-menu): Use media query
    backgroundColor: tokens.colors.$white,
    alignSelf: 'center',
    // TODO(simplified-menu): Update to use tokens, or add Media Query
    maxWidth: 620,
  },
  containerPadding: {
    paddingHorizontal: 16,
  },
  containerTitle: {
    marginVertical: 8,
  },
  textTitle: {
    textAlign: 'center',
  },
  textSubTitle: {
    textAlign: 'center',
  },
  textSectionTitle: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 20,
    paddingVertical: 8,
  },
  containerImageBackground: {
    flex: 1,
    minHeight: 200,
    paddingVertical: 18,
  },
  image: {
    height: '100%',
    paddingVertical: 18,
  },
  textDescription: {
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  containerSection: {
    marginVertical: 16,
  },
  productDetailSectionHeader: {
    padding: 16,
    gap: 16,
  },
  comboUpSellButton: {
    padding: 16,
    gap: 16,
  },
});
