import { Link, router } from 'expo-router';
import { useEffect, useMemo } from 'react';
import { SafeAreaView, SectionList, StyleSheet } from 'react-native';
import { View } from 'react-native';

import { Button, IconChevronDown, IconLocked, Text, tokens } from '@fhs/ui';

import { CheckoutPaymentDetails } from '../components/checkout-payment-details';
import { CheckoutPickupDetails } from '../components/checkout-pickup-details';
import { Debugger } from '../debugger';
import { CartModel, CartState } from '../types';

import { CommitOrder, GetCart, useMutation, useQuery } from './client';

type Data =
  | {
      title: 'PickupDetails';
      data: void[];
    }
  | { title: 'PaymentDetails'; data: void[] };

export function CheckoutScreen() {
  const { data: cart } = useQuery(GetCart);
  const subtotal = cart?.entries.reduce((p, { price }) => p + price, 0);
  const [{ fetching: committingOrder, error }, commitOrder] = useMutation(CommitOrder);

  const data = useMemo<Data[]>(() => {
    return [
      { title: 'PickupDetails', data: [void 0] },
      { title: 'PaymentDetails', data: [void 0] },
    ];
  }, []);

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  return (
    <View style={styles.page}>
      <Debugger />
      {committingOrder && <View style={styles.uiblock} />}

      <SectionList
        sections={data}
        style={styles.flex1}
        contentContainerStyle={[styles.container, styles.list]}
        ListEmptyComponent={() => {
          return (
            <View style={{ flex: 1, padding: 16, alignItems: 'center' }}>
              <Text.Heading type="one">No Cart Entries</Text.Heading>
            </View>
          );
        }}
        renderSectionHeader={({ section }) => {
          switch (section.title) {
            case 'PickupDetails':
              return (
                <Text.Ui size="md" weight="bold">
                  Pick Up Details
                </Text.Ui>
              );
            case 'PaymentDetails':
              return (
                <Text.Ui size="md" weight="bold">
                  Payment Details
                </Text.Ui>
              );
            default:
              return null;
          }
        }}
        renderItem={({ section }) => {
          switch (section.title) {
            case 'PickupDetails':
              return <CheckoutPickupDetails />;
            case 'PaymentDetails':
              return <CheckoutPaymentDetails />;
          }
        }}
      />

      <SafeAreaView>
        <View style={styles.subtotalContainer}>
          <View style={[styles.container, styles.subtotalRow]}>
            <Text.Ui size="md" weight="semibold">
              Cart Total:
            </Text.Ui>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text.Ui size="md" weight="semibold">
                ${subtotal / 100}
              </Text.Ui>
              <IconChevronDown color={tokens.colors.$houseRedDarken} size={24} />
            </View>
          </View>
        </View>
        <View style={styles.container}>
          <ActionButton commitOrder={commitOrder} loading={committingOrder} state={cart.state} />
        </View>
      </SafeAreaView>
    </View>
  );
}

function ActionButton({
  state,
  loading,
  commitOrder,
}: {
  state: CartState;
  loading: boolean;
  commitOrder: () => Promise<CartModel>;
}) {
  switch (state) {
    case 'LOYALTY_INVALID':
      // todo, show loyalty error states
      return (
        <Button disabled>
          <Button.Icon>
            <IconLocked />
          </Button.Icon>
          <Button.Text>Place Secure Order</Button.Text>
        </Button>
      );

    case 'NEEDS_LOYALTY_VALIDATION':
      return (
        <Button loading>
          <Button.Text>Validating Offers</Button.Text>
        </Button>
      );

    case 'PRICE_REQUESTED':
    default:
      return (
        <Link
          href="/v2/order"
          asChild
          onPress={async e => {
            e.preventDefault();
            const order = await commitOrder();
            router.replace(`/v2/order/${order.metadata.rbiOrderId}`);
          }}
        >
          <Button loading={loading}>
            <Button.Icon>
              <IconLocked />
            </Button.Icon>
            <Button.Text>Place Secure Order</Button.Text>
          </Button>
        </Link>
      );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
  container: {
    marginHorizontal: 'auto',
    padding: 16,
    width: 375,
  },
  flex1: {
    flex: 1,
  },
  list: {
    gap: 16,
  },
  subtotalContainer: {
    backgroundColor: tokens.colors.$houseLight,
    width: '100%',
    height: 48,
  },
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uiblock: {
    backgroundColor: tokens.colors.$black10,
    zIndex: 1000,
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
