import { type ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import { RadioGroup, Text, YStack, tokens, usePressableState, useRadioItem } from '@fhs/ui';

export type SizeCardProps = {
  /** Card title */
  title: string;
  /** Card description. Rendered underneath title */
  description: string;
  /** Render function for an icon. Given the disabled and selected states so a different icon be rendered depending on state */
  renderIcon: ({ disabled, selected }: { disabled: boolean; selected: boolean }) => ReactNode;
  /** If the radio group is disabled */
  disabled?: boolean;
};

/**
 * SizeCard component renders a card component hooked into ancestor radio item state.
 */

export function SizeCard(props: SizeCardProps) {
  const radioItem = useRadioItem();

  return (
    <RadioGroup.Pressable
      pressedStyle={{ borderColor: tokens.colors.$houseYellow }}
      style={[styles.container, radioItem.selected && { borderColor: tokens.colors.$houseYellow }]}
      borderRadius={8}
    >
      <Icon selected={radioItem.selected} renderIcon={props.renderIcon} />
      <TextContainer
        selected={radioItem.selected}
        title={props.title}
        description={props.description}
      />
    </RadioGroup.Pressable>
  );
}

function Icon({ selected, renderIcon }) {
  const pressableState = usePressableState();

  if (!renderIcon) {
    return null;
  }

  const shouldIconRenderAsSelected = pressableState.pressed || selected;

  return renderIcon({ disabled: pressableState.disabled, selected: shouldIconRenderAsSelected });
}

function TextContainer({ selected, title, description }) {
  const pressableState = usePressableState();
  const shouldTextBeMuted = (!pressableState.pressed && !selected) || pressableState.disabled;
  return (
    <YStack style={styles.textContainer}>
      <Text.Ui size="md" weight="semibold" style={[shouldTextBeMuted && styles.textMuted]}>
        {title}
      </Text.Ui>
      <Text.Ui size="sm" style={[shouldTextBeMuted && styles.textMuted]}>
        {description}
      </Text.Ui>
    </YStack>
  );
}

const styles = StyleSheet.create({
  container: {
    flexBasis: 100,
    flexShrink: 0,
    flexGrow: 1,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    paddingTop: 20,
    paddingRight: 12,
    paddingBottom: 16,
    paddingLeft: 12,
    minWidth: 114,
    gap: 8,
  },
  textContainer: {
    alignItems: 'center',
  },
  textMuted: {
    color: tokens.colors.$disabledText,
  },
});
