import { Link, router } from 'expo-router';
import { Pressable, StyleSheet, View } from 'react-native';

import {
  IconCalendar,
  IconChevronRight,
  IconDrive,
  IconInfoFill,
  IconStore,
  Text,
  tokens,
} from '@fhs/ui';

export function CheckoutPickupDetails() {
  return (
    <View style={styles.container}>
      <View style={styles.sectionBorder}>
        <View style={styles.section}>
          <View style={styles.iconCircle}>
            <IconStore size={24} />
          </View>
          <View style={styles.flex1}>
            <Text.Heading numberOfLines={1} type="three">
              Mandarin
            </Text.Heading>
            <Text.Ui numberOfLines={1} size="md">
              9825 San Jose Blvd, Jacksonville
            </Text.Ui>
          </View>
          <View>
            <Link href="/store-locator?back=/v2/cart/(checkout)" asChild>
              <Text.Ui style={styles.link} size="sm">
                Change
              </Text.Ui>
            </Link>
          </View>
        </View>

        <View style={styles.locationCalloutContainer}>
          <IconInfoFill />
          <Text.Ui size="xs">No refunds for orders to the wrong location</Text.Ui>
        </View>
      </View>

      <Pressable style={styles.linkSection} onPress={() => router.setParams({ action: 'time' })}>
        {props => (
          <View
            style={[styles.section, styles.sectionBorder, props.pressed && styles.activeSection]}
          >
            <View style={[styles.iconCircle, props.pressed && styles.iconCirclePressed]}>
              <IconCalendar size={24} />
            </View>

            <View style={styles.flex1}>
              <Text.Ui style={styles.marginBottom4} size="md" weight="bold">
                Today
              </Text.Ui>
              <Text.Ui size="md">Aug 1, 2023</Text.Ui>
            </View>

            <View style={styles.timeAndArrow}>
              <Text.Ui size="md" weight="semibold">
                12:15 AM
              </Text.Ui>
              <IconChevronRight size={24} />
            </View>
          </View>
        )}
      </Pressable>

      <Pressable
        style={styles.linkSection}
        onPress={() => router.setParams({ action: 'service-mode' })}
      >
        {props => (
          <View style={[styles.section, props.pressed && styles.activeSection]}>
            <View style={[styles.iconCircle, props.pressed && styles.iconCirclePressed]}>
              <IconDrive size={24} />
            </View>

            <View style={styles.flex1}>
              <Text.Ui style={styles.marginBottom4} size="md" weight="bold">
                Rapid Rescue Lane
              </Text.Ui>
              <Text.Ui size="md">Drive to the pick up window</Text.Ui>
            </View>

            <IconChevronRight size={24} />
          </View>
        )}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  flex1: { flex: 1 },
  timeAndArrow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
  },
  activeSection: {
    backgroundColor: tokens.colors.$houseLight,
  },
  linkSection: {
    width: '100%',
  },
  section: {
    width: '100%',
    gap: 8,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionBorder: {
    width: '100%',
    borderBottomColor: tokens.colors.$black10,
    borderBottomWidth: 1,
  },
  locationCalloutContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    marginHorizontal: 16,
    marginBottom: 12,
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: tokens.colors.$houseLight,
    flex: 1,
    borderRadius: 8,
  },
  link: {
    textDecorationLine: 'underline',
  },
  iconCirclePressed: {
    backgroundColor: tokens.colors.$black10,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
  },
  marginBottom4: {
    marginBottom: 4,
  },
  container: {
    alignItems: 'center',
    width: '100%',
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: 1,
    borderRadius: 8,
  },
});
