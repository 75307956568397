import { StyleSheet, View } from 'react-native';

import { IconStarRateFill, Text, tokens } from '@fhs/ui';

export function OrderCartInformation() {
  return (
    <>
      <Text.Heading type="four" style={styles.marginBottom12}>
        Your Order
      </Text.Heading>
      <View style={styles.container}>
        <View style={[styles.section, styles.padding16, styles.row]}>
          <View>
            <Text.Ui style={styles.marginBottom4} size="md" weight="bold">
              Medium Hook and Ladder
            </Text.Ui>
            <Text.Ui size="sm">White Bread</Text.Ui>
          </View>
          <Text.Ui size="sm">$10.98</Text.Ui>
        </View>

        <View style={[styles.section, styles.padding16]}>
          <View style={styles.row}>
            <Text.Ui size="sm">Subtotal</Text.Ui>
            <Text.Ui size="sm">$18.88</Text.Ui>
          </View>
          <View style={styles.row}>
            <Text.Ui size="sm">Savings</Text.Ui>
            <Text.Ui size="sm">$0.10</Text.Ui>
          </View>
          <View style={styles.row}>
            <Text.Ui size="sm">Donation</Text.Ui>
            <Text.Ui size="sm">$1.00</Text.Ui>
          </View>
          <View style={styles.row}>
            <Text.Ui size="sm">Taxes</Text.Ui>
            <Text.Ui size="sm">$1.00</Text.Ui>
          </View>
        </View>

        <View style={[styles.section, styles.padding16]}>
          <View style={styles.row}>
            <Text.Ui size="sm">Available Points</Text.Ui>
            <Text.Ui size="sm">
              1025 <IconStarRateFill color={tokens.colors.$houseYellow} />
            </Text.Ui>
          </View>
          <View style={styles.row}>
            <Text.Ui size="sm">Redeeming</Text.Ui>
            <Text.Ui size="sm">
              -90 <IconStarRateFill color={tokens.colors.$houseYellow} />
            </Text.Ui>
          </View>
        </View>

        <View style={[styles.section, styles.padding16]}>
          <View style={styles.row}>
            <Text.Ui size="md" weight="bold">
              Total:
            </Text.Ui>
            <Text.Ui size="md" weight="bold">
              $20.98
            </Text.Ui>
          </View>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    width: '100%',
    borderBottomColor: tokens.colors.$black10,
    borderBottomWidth: 1,
  },
  padding16: {
    padding: 16,
  },
  marginBottom4: {
    marginBottom: 4,
  },
  marginBottom12: {
    marginBottom: 12,
  },
  row: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  container: {
    alignItems: 'center',
    minHeight: 200,
    width: 343,
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: 1,
    borderRadius: 8,
  },
});
